/*check all opt table items*/


$(document).ready(()=>{
  window.reInitCatalogItems();
});



window.reInitCatalogItems = ()=>{


  let arWishedID = [];
  let arXCart = [];
  let arAdd2Basket = [];

  let subs = [];

  $('.x-wish').each(function (){
    if ($(this).data('id')){
      arWishedID.push($(this).data('id'));
    }
  })

  $(".x-cart").each(function () {
    if ($(this).data('id')){
      arXCart.push($(this).data('id'));
    }
  })
  $(".x-add2basket").each(function () {
    if ($(this).data('id')){
      arAdd2Basket.push($(this).data('id'));
    }
  })


  $('.x-order').each(function (){
    if ($(this).data('id')){
      subs.push($(this).data('id'));
    }
  })

  $.ajax({
    type: "POST",
    url: "/local/ajax/catalogResolver.php",
    data: {
      whishItems: arWishedID,
      addableItems: arXCart,
      productItem:arAdd2Basket,
      subs:subs,
    },
    success: function (data) {
      if (!data){
        return;
      }
      data = JSON.parse(data);

      if (data.whishItems){
        for (const [id, value] of Object.entries(data.whishItems)) {
          let $wishItem = $('[data-id="'+id+'"].x-wish');
          if (value) {
            $wishItem.addClass("active");
            $wishItem.find(".x-wish-in").addClass("hide");
            $wishItem.find(".x-wish-out").removeClass("hide");
          } else {
            $wishItem.removeClass("active");
            $wishItem.find(".x-wish-in").removeClass("hide");
            $wishItem.find(".x-wish-out").addClass("hide");
          }
        }
      }

      if (data.addableItems){
        for (const [id, value] of Object.entries(data.addableItems)) {
          let $addAddableItem = $('[data-id="'+id+'"].x-cart');
          if (value) {
            $addAddableItem.find(".x-active").removeClass("hide");
            $addAddableItem.find(".x-not-active").addClass("hide");
          } else {
            $addAddableItem.find(".x-active").addClass("hide");
            $addAddableItem.find(".x-not-active").removeClass("hide");
          }
        }
      }

      if (data.productItem){
        for (const [id, value] of Object.entries(data.productItem)) {
          if (value) {
            let $productItem = $('[data-id="'+id+'"].x-add2basket');
            $productItem.closest(".x-add-container").addClass("hide");
            $productItem
              .closest(".x-add-container")
              .next(".x-in-basket")
              .removeClass("hide");
          }
        }
      }


      if (data.subs){
        for (const [id, value] of Object.entries(data.subs)) {
          if (value) {
            console.log('detect sub for ' + id);
            let $sub = $('[data-id="'+id+'"].x-prodsub');
            $sub.addClass("hide");
            $sub
                .next(".x-prodsub")
                .attr("data-subscribeId", res)
                .removeClass("hide");
          }
        }
      }
    },
  });
}


$(document).on('change', 'input#select_all_items', function () {
  var _this = $(this)

  let $controls = $('[data-entity="con-controls"]')
  console.log($controls)

  /**
   *
   * todo
   * разобраться что за тип с дата атрибутом в оригинале
   * data-product_type=3
   */
  const notOfferCount = $('.flow:not([data-product_type=3])').find('.x-add2basket').length
  const wishCount = $('.flow:not([data-product_type=3])').find('.x-wish').length
  const allGoodsCount = $('.flow').length

  if (_this.is(':checked')) {
    //$('.opt_action').removeClass('no-action');

    //buy && wish
    if (notOfferCount != 0) {
      $('.button__span:not([data-action=compare])').removeClass('no-action')
    }

    //compare
    $('.button__span[data-action=compare]').removeClass('no-action')

    $('.button__span').addClass('animate-load')
    $('.button__span .text').remove()

    //buy
    $('<span class="button__span">(' + notOfferCount + '</>)</span>').appendTo($('.button[data-action=buy]'))

    //wish
    $('<span class="button__span">(' + wishCount + '</span>').appendTo($('.button[data-action=wish]'))

    //compare
    $('<span class="button__span">(' + allGoodsCount + ')</span>').appendTo($('.button[data-action=compare]'))

    $('input[name="chec_item"]').prop('checked', 'checked')

    $controls.removeClass('con__grid_js-disable')
  } else {
    $('.button__span').addClass('no-action')
    $('.button__span').removeClass('animate-load')
    $('.button__span').remove()

    $('input[name="chec_item"]').removeAttr('checked')
    $controls.addClass('con__grid_js-disable')
  }
})

/*check opt table item*/
$(document).on('change', 'input[name=\'chec_item\']', function () {
  let totalWish = 0
  let totalCart = 0
  $('.flow').each((index, elem) => {

    if ($(elem).find('[name="chec_item"]').is(':checked')) {
      if ($(elem).find('.x-add2basket').length) {
        totalCart++
      }

      if ($(elem).find('.x-wish').length) {
        totalWish++
      }
    }
  })

  $('.button__span').remove()
  if (totalWish) {
    //wish
    $('<span class="button__span">(' + totalWish + '</span>').appendTo($('.button[data-action=wish]'))
    //compare
    $('<span class="button__span">(' + totalWish + ')</span>').appendTo($('.button[data-action=compare]'))
  }

  if (totalCart) {
    $('<span class="button__span">(' + totalCart + '</>)</span>').appendTo($('.button[data-action=buy]'))
  }
})

/*buy|wish|compare opt table items*/
$(document).on('click', '[data-entity="opt_action"]', function () {

  console.log('call opt')
  var _this = $(this),
    action = _this.data('action'),
    basketParams = {
      type: 'multiple',
      iblock_id: _this.data('iblock_id'),
      action: action,
      items: {}
    }

  $controlWrapper = $('[data-entity="con-controls"]')

  if (!$controlWrapper.hasClass('con__grid_js-disable')) {
    $controlWrapper.addClass('con__grid_js-disable')

    $('.flow').each(function () {
      var _this = $(this)
      var canBuy = _this.find('.x-add2basket').length //console.log(basketParams);
      var add = _this.find('input[name="chec_item"]').is(':checked') && (canBuy || (action != 'buy'))
      if (add) {
        basketParams['items'][_this.data('id')] = {}
        basketParams['items'][_this.data('id')]['id'] = _this.data('id')
        basketParams['items'][_this.data('id')]['product_type'] = _this.data('product_type')
        basketParams['items'][_this.data('id')]['quantity'] = _this.find('input[name="quantity"]').val()
      }
    })
    $.ajax({
      type: 'POST',
      url: '/ajax/item.php',
      data: basketParams,
      dataType: 'json',
      success: function (data) {
        if ('STATUS' in data) {
          $.ajax({
            type: 'POST',
            url: '/local/templates/ipmc_common/include_areas/baskets/mobile_header_ajax.php',
            success: function (html) {
              $('#cetera_header_mobile').html(html)
            }
          })
          $.ajax({
            type: "POST",
            url: "/local/templates/ipmc_common/include_areas/baskets/side_buttons_ajax.php",
            success: function (html) {
              $("#cetera_buttons").html(html);
            }
          });
          $.ajax({
            type: "POST",
            url: "/local/templates/ipmc_common/include_areas/baskets/side_basket_ajax.php",
            success: function (html) {
              $("#side_basket").html(html);
            }
          });
        }
        $controlWrapper.removeClass('con__grid_js-disable')
      },
      error:function (data){
        $controlWrapper.removeClass('con__grid_js-disable');
      }
    })
  }
})
