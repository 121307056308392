

$(document).on('click','[data-action="sendComment"]',function (e){

  e.preventDefault();
  e.stopPropagation();
  let form  =$('#form_comment');

  let comment = form.find('[name=comment]');
  let limitations = form.find('[name=limitations]');
  let virtues = form.find('[name=virtues]');
  let rating = form.find('[name=rating]');





  let resultCommentText = '';
  if(virtues.val()) {
    resultCommentText += '<virtues>'+virtues.val()+'</virtues>'+'\n';
  }

  if(limitations.val()) {
    resultCommentText += '<limitations>'+limitations.val()+'</limitations>'+'\n';
  }

  if(comment.val()) {
    resultCommentText += '<comment>'+comment.val()+'</comment>';
  }

  if(resultCommentText) {
    comment.val(resultCommentText);
  }
  let ratingVal = form.find('.rating__input:checked').data('star');
  if(ratingVal) {
    rating.val(ratingVal);
  }
  $.ajax({
    type: "POST",
    url: form.attr('action'),
    data: form.serialize(),
    success: function (data) {
      try{
        parse = JSON.parse(data);
      }catch (e){
        parse = {};
      }

      if (parse.error){
        let capctcha = $('#captcha');
        capctcha.attr('src',parse.captcha);
        $('#captcha_code').val(parse.captchaCode);
        let errorBlock = $('#comments-form-error');
        errorBlock.html(parse.error);
        errorBlock.removeClass('hide');
        $([document.documentElement, document.body]).animate({
          scrollTop: errorBlock.offset().top
        }, 800);
      }else{
        console.log('insert');
        let html = $(data);
        $('#form_comment_0').html(html);
        $('#write-rev').remove();
      }
    }
  });
})

;
$(document).on('click', '[data-action="comments-sort-action"]', function(){
  var _this = $(this);
  var ajaxData = _this.data('review_sort_ajax');

  var container = _this.closest('[id^=comp_]');
  containerId = container.attr('id');
  if(containerId !== undefined && containerId) {
    ajaxData.containerId = containerId;
  }
  if(ajaxData !== undefined) {
    container.addClass('blur')
    $.ajax({
      type: 'get',
      url:ajaxData.ajax_url,
      data: ajaxData,
      success: function(html){
       container.html(html);
      }
    });
  }
});

$(document).on('click','[data-toggle="write-rev"]',function (){
  $('#write-rev').toggleClass('hide');
})
