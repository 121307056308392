Foundation.addToJquery($);
$(document).ready(()=>{
  const DELIVERY_PREVIEW_WRAPPER = $('[data-entity="delivery-preview"]');
  if (DELIVERY_PREVIEW_WRAPPER.length){

    $.ajax({
      url: '/ajax/delivery.php',
      type: 'POST',
      data: {
        is_preview: 'Y',
        index: 1001,
        product_id: DELIVERY_PREVIEW_WRAPPER.data('item-id'),
        quantity: 1
      },
      beforeSend: function(){
      },
      success: function(response){
        DELIVERY_PREVIEW_WRAPPER.html(response);
      },
      error: function(xhr, ajaxOptions, thrownError){
      },
      complete: function(){

      }
    });
  }
})

$(document).on('click','[data-action="show-delivery-item-popup"]',function (e){
  e.preventDefault();
  e.stopPropagation();
  $.ajax({
    url: '/ajax/form.php',
    type: 'POST',
    data: {
      is_preview:'N',
      form_id:'delivery',
      product_id: $(this).data('product-id'),
      quantity: $(this).data('quantity'),
      force_disable:'Y',
    },
    beforeSend: function(){
    },
    success: function(response){
      if ($('[data-entity="city-choose"]').length){
        $('[data-entity="city-choose"]').html(response);
        let popup = new Foundation.Reveal($('[data-entity="city-choose"]'));
        popup.open();
      }
    },
    error: function(xhr, ajaxOptions, thrownError){
    },
    complete: function(){

    }
  });

});
