export default class DeliveryCost {
  constructor () {
    this.quantutyInputSelector = '#catalog-delivery-quantity'
    this.basketCheckBoxSelector = '#catalog-delivery-addbasket'
    this.buttonPlusSelector = '[data-action="plusQuantity"]'
    this.buttonMinusSelector = '[data-action="minusQuantity"]'
    this.timeout = null

    // $(document).on('change', this.quantutyInputSelector, this.onChangeQuantity.bind(this))
    // $(document).on('change', this.basketCheckBoxSelector, this.onChangeQuantity.bind(this))
    $(document).on('click', this.buttonPlusSelector, this.onPlusClick.bind(this))
    $(document).on('click', this.buttonMinusSelector, this.onMinusClick.bind(this))
    $(document).on('click','[data-action="changeLocationDelivery"]',this.timer.bind(this));

  }


  onChangeBasketItems (el) {

  }

  onPlusClick (e, el) {
    $(this.quantutyInputSelector).val(parseInt($(this.quantutyInputSelector).val()) + 1);
    this.timer();
  }

  onMinusClick (e, el) {
    if ($(this.quantutyInputSelector).val() > 1) {
      $(this.quantutyInputSelector).val(parseInt($(this.quantutyInputSelector).val()) - 1);
      this.timer();
    }
  }

timer(){
  let self = this;
  clearTimeout(this.timeout);
  self.form = $('[data-entity="ipmc-catalog-delivery" ]');
  self.form.find('[name="product_id"]').val($('[data-entity="delivery-preview"]').data('item-id'));

  let locSearch = self.form.find('[name="LOCATION_SEARCH"]');
  let location = self.form.find('[name="LOCATION"]');

  let locChanged = self.form.find('[name="LOCATION_CHANGED"]');

  if ( locSearch.val() !== location.val()){
    locChanged.val('Y');
  }


  this.timeout = setTimeout(()=>{
    $.ajax({
      url: self.form.attr('action'),
      type: 'GET',
      data: self.form.serialize(),
      beforeSend: function(){
      },
      success: function(response){
      },
      error: function(xhr, ajaxOptions, thrownError){
      },
      complete: function(){

      }
    });
  },100);
}
}
