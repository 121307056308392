$(document).on('click','[data-action="quant-plus"]',function (){
  let input = $(this).siblings('input');
  input.val(parseInt(input.val()) + 1);
});

$(document).on('click','[data-action="quant-minus"]',function (){
  let input = $(this).siblings('input');
  if (parseInt(input.val()) !== 0){
    input.val(parseInt(input.val()) - 1);
  }
});
